import React from 'react';
import Search from './Search';
import Footer from './Footer';
import { Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box flexGrow={1} >
            <Search />
        </Box>
        <Footer />
    </Box>
);
}

export default App;
