import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box sx={{ width: '100%', mt: 5, backgroundColor: '#F34B01', p: 1, textAlign: 'center' }}>
            <Typography fontSize={12} variant="body1" color="white">
               Copyright © Adam Matar
            </Typography>
        </Box>
    );
};

export default Footer;
