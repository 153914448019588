import React, { useState } from 'react';
import Papa from 'papaparse';
import { Container, Grid, TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Box } from '@mui/material';
import ImageShadow from 'react-image-shadow';
import Alert from '@mui/material/Alert';
import 'react-image-shadow/assets/index.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import { Snackbar } from '@mui/material';

const Search = () => {
    const [filters, setFilters] = useState({
        id: '',
        lastName: '',
        firstName: '',
        fatherName: '',
    });
    const [results, setResults] = useState([]);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    const handleFilterChange = (e, filterName) => {
        setFilters({ ...filters, [filterName]: e.target.value });
    };

    const headers = [
        { key: 'מספר זהות', displayName: 'رقم الهوية' },
        { key: 'שם משפחה', displayName: 'اسم العائلة' },
        { key: 'שם פרטי', displayName: 'الاسم الشخصي' },
        { key: 'שם אב', displayName: 'اسم الاب' },
        { key: 'סמל קלפי', displayName: 'رقم الصندوق' },
        { key: 'מספר סידור קלפי', displayName: 'الرقم التسلسلي' },
        { key: 'מיקום קלפי', displayName: 'مكان الصندوق' },
    ];

    const handleSearch = () => {
        if (filters.id === '') {
            setResults(['الرجاء ادخال رقم الهوية']);
            return; // Do not perform the search
        }

        // Remove leading zeros from the ID filter
        const idWithoutLeadingZeros = filters.id.replace(/^0+/, '');

        Papa.parse(process.env.PUBLIC_URL + '/data.csv', {
            download: true,
            header: true,
            complete: (result) => {
                const filtered = result.data.filter((row) =>
                    (idWithoutLeadingZeros === '' || row['מספר זהות'] === (idWithoutLeadingZeros))
                );
                setResults(filtered);
            },
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const formatResultForCopy = (result) => {
        return headers.map(header => `${header.displayName}: ${result[header.key]}`).join('\n');
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 5 , px: 4 }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
                <ImageShadow src='TajmoaLogo.png' alt="Tajamoa Logo Tira" />
            </div>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={12}>
                    <TextField
                        dir="rtl"
                        fullWidth
                        variant="outlined"
                        label="رقم الهوية"
                        value={filters.id}
                        onChange={(e) => handleFilterChange(e, 'id')}
                        onKeyPress={handleKeyPress}
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item container justifyContent="center">
                    <Button onClick={handleSearch} variant="contained" color="primary"
                        sx={{
                            margin: '10px', // Add margin
                            '&:hover': {
                                backgroundColor: '#003466', // Change color on hover for a modern look
                            },
                        }}
                        startIcon={<SearchIcon />} // Add the search icon to the start of the button
                    >
                        بحث
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {results.length > 0 && typeof results[0] === 'string' ? (
                        <Alert severity="warning" dir="rtl">{results[0]}</Alert>
                    ) : (
                        results.map((result, index) => (
                            <TableContainer key={index} component={Paper} sx={{ mt: 2, borderRadius: 2, elevation: 3, overflow: 'hidden' }}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {headers.map((header) => (
                                            <TableRow key={header.key} sx={{ '&:hover': { backgroundColor: 'rgba(243, 82, 1, 0.14)' } }}>
                                                <TableCell align="center" sx={{ width: '60%' }}>{result[header.key]}</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', width: '40%' }}>
                                                    {header.displayName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                <CopyToClipboard text={formatResultForCopy(result)} onCopy={() => {
    setCopiedIndex(index);
    setSnackbarMessage('! تم نسخ النتيجة بنجاح');
    setSnackbarOpen(true);
}}>
    <Button variant="contained" sx={{ backgroundColor: '#F34B01', margin: '10px', '&:hover': { backgroundColor: 'darkorange' }, }} startIcon={<ContentCopyIcon />}>
        انسخ النتيجة
    </Button>
</CopyToClipboard>

                                </Box>
                            </TableContainer>
                        ))
                    )}
                </Grid>
            </Grid>
            <Snackbar
    open={snackbarOpen}
    autoHideDuration={3000} // Adjust the duration as needed
    onClose={() => setSnackbarOpen(false)}
    message={snackbarMessage}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position the Snackbar
    sx={{ '& .MuiSnackbarContent-root': { justifyContent: 'center' } }}
/>

        </Container>
    );
};

export default Search;
